"use client";

import { Image } from "antd";
import React from "react";
import Ashish_Baghel from "../../assets/events_images/speakers/Ashish_Baghel.png";
import Deepti_Baghel from "../../assets/events_images/speakers/Deepti_Baghel.png";
import Kiran_Ramireddi from "../../assets/events_images/speakers/Kiran_Ramireddi.png";
import Luis_Gonzalez from "../../assets/events_images/speakers/Luis_Gonzalez.png";
import Phani_Kalyan_Cherukuri from "../../assets/events_images/speakers/Phani_Kalyan_Cherukuri.png";
import Matt_McDonald from "../../assets/events_images/speakers/Matt_McDonald.png";
import Srini_Nanduri from "../../assets/events_images/speakers/Srini_Nanduri.png";
import Shankar_Vedaraman from "../../assets/events_images/speakers/Shankar_Vedaraman.png";
import Beth_McCormick from "../../assets/events_images/speakers/Beth_McCormick.jpeg";
import Anish_Jacob from "../../assets/events_images/speakers/Anish_Jacob.jpeg";
import Kristen_Kalupski from "../../assets/events_images/speakers/Kristen_Kalupski.jpeg";
import Sanjay_Gwalani from "../../assets/events_images/speakers/Sanjay_Gwalani.png";
import Danielle_Crop from "../../assets/events_images/speakers/Danielle_Crop.png";
import Matt_Scully from "../../assets/events_images/speakers/Matt_Scully.jpeg";
import comingSoon from "../../assets/events_images/coming-soon.png";
interface SpeakersMember {
  image: string;
  name: string;
  designation: string;
  id?: number;
  isPlaceholder: boolean;
}

const SpeakersMemberData: SpeakersMember[] = [
  // {
  //   image: Beth_McCormick,
  //   name: "Beth McCormick",
  //   designation: "CAVA- CIO",
  //   isPlaceholder: false,
  // },
  {
    image: Phani_Kalyan_Cherukuri,
    name: "Phani Kalyan Cherukuri",
    designation: "American Express- Vice President, Engineering",
    isPlaceholder: false,
  },
  {
    image: Anish_Jacob,
    name: "Anish Jacob",
    designation: "Jenius Bank- Managing Director & CIO",
    isPlaceholder: false,
  },

  {
    image: Shankar_Vedaraman,
    name: "Shankar Vedaraman",
    designation: "Salesforce- Vice President, Data and Analytics",
    isPlaceholder: false,
  },
  {
    image: Sanjay_Gwalani,
    name: "Sanjay Gwalani",
    designation: "American Express- Vice President, Risk Product & Platform Development",
    isPlaceholder: false,
  },
  {
    image: Ashish_Baghel,
    name: "Ashish Baghel",
    designation: "NucleusTeq & NuoData- Founder and CEO",
    isPlaceholder: false,
  },
  {
    image: Danielle_Crop,
    name: "Danielle Crop",
    designation: " Fortune 100 CDO- Advisor and Board Member",
    isPlaceholder: false,
  },
  {
    image: Srini_Nanduri,
    name: "Srini Nanduri",
    designation:
      "Republic Services- VP, Head of Data Science AI/ML and Enterprise Analytics",
    isPlaceholder: false,
  },
  {
    image: Luis_Gonzalez,
    name: "Luis Gonzalez",
    designation: "PetSmart- Senior Director, Data Engineering and Architecture",
    isPlaceholder: false,
  },

  {
    image: Matt_McDonald,
    name: "Matt McDonald",
    designation:
      "CAVA- Senior Director - Data Engineering, Data Platforms, and Analytics",
    isPlaceholder: false,
  },
  {
    image: Kristen_Kalupski,
    name: "Kristen Kalupski",
    designation: "NucleusTeq- CMO",
    isPlaceholder: false,
  },
  {
    image: Kiran_Ramireddi,
    name: "	Kiran Ramireddi",
    designation: "NucleusTeq- EVP, Strategy",
    isPlaceholder: false,
  },
  {
    image: Deepti_Baghel,
    name: "Deepti Baghel",
    designation: "NuoData- VP, Technology",
    isPlaceholder: false,
  },
];

const Speakers: React.FC = () => {
  return (
    <>
      <div className="speakers-area  pb-0">
        <div className="speakers-section">
          <div className="container">
            <div className="speakers-section-title">
              <span>Listen to the keynote speakers</span>
              <h2>Who&apos;s Speaking</h2>
            </div>
          </div>
          <div className="bg-title">Speakers</div>
        </div>

        <div className="speakers-row row m-0">
          {SpeakersMemberData &&
            SpeakersMemberData.slice(0, 12).map((value, i) => (
              <div className="col-lg-3 col-sm-6 p-0" key={i}>
                <div className="elkevent-single-speakers">
                  <>
                    <Image
                      src={value.image}
                      preview={false}
                      alt="speaker"
                      width={"100%"}
                      height={"100%"}
                    />
                    <div className="speakers-content">
                      <h3>{value.name}</h3>
                      <span>{value.designation}</span>
                    </div>
                  </>
                </div>
              </div>
            ))}
          {SpeakersMemberData.length % 4 == 0 && (
            <div className="col-lg-12 col-sm-12 p-0" key={"comingsoon"}>
              <div className="elkevent-single-speakers">
                <div className="more-speakers-content">
                  <h3>
                    More speakers
                    <br />
                    <span>coming soon!</span>
                  </h3>
                </div>
              </div>
            </div>
          )}
          {SpeakersMemberData.length % 4 == 1 && (
            <div className="col-lg-9 col-sm-6 p-0" key={"comingsoon"}>
              <div className="elkevent-single-speakers">
                <div className="more-speakers-content">
                  <h3>
                    More speakers
                    <br />
                    <span>coming soon!</span>
                  </h3>
                </div>
              </div>
            </div>
          )}
          {SpeakersMemberData.length % 4 == 2 && (
            <div className="col-lg-6 col-sm-12 p-0" key={"comingsoon"}>
              <div className="elkevent-single-speakers">
                <div className="more-speakers-content">
                  <h3>
                    More speakers
                    <br />
                    <span>coming soon!</span>
                  </h3>
                </div>
              </div>
            </div>
          )}
          {SpeakersMemberData.length % 4 == 3 && (
            <div className="col-lg-3 col-sm-6 p-0" key={"comingsoon"}>
              <div className="elkevent-single-speakers">
                <div className="more-speakers-content">
                  <h3>
                    More speakers
                    <br />
                    <span>coming soon!</span>
                  </h3>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default Speakers;
