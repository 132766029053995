"use client";

import { Col, Row } from "antd";
import React from "react";
import aws from "../../../assets/events_images/companies-icon/aws.svg";
import aaa from "../../../assets/events_images/companies-icon/aaa.jpg";
import ibm from "../../../assets/events_images/companies-icon/ibm.svg";
import amex from "../../../assets/events_images/companies-icon/amex.svg";
import citizensBank from "../../../assets/events_images/companies-icon/citizens-bank.jpg";
import cava from "../../../assets/events_images/companies-icon/cava.jpg";
import mckesson from "../../../assets/events_images/companies-icon/mckesson.jpg";
import republicServices from "../../../assets/events_images/companies-icon/republic-services.jpg";
import jeniusBank from "../../../assets/events_images/companies-icon/jenius-bank.jpg";
import mmc from "../../../assets/events_images/companies-icon/mmc.jpg";
import goDaddy from "../../../assets/events_images/companies-icon/go-daddy.jpg";
import bannerHealth from "../../../assets/events_images/companies-icon/banner-health.svg";
import blueCross from "../../../assets/events_images/companies-icon/blue-cross.svg";
import sprouts from "../../../assets/events_images/companies-icon/sprouts.svg";
import discountTire from "../../../assets/events_images/companies-icon/discount-tire.svg";
import diamondBacks from "../../../assets/events_images/companies-icon/diamond-backs.svg";
import petsmart from "../../../assets/events_images/companies-icon/petsmart.svg";
import asu from "../../../assets/events_images/companies-icon/asu.svg";
import circleK from "../../../assets/events_images/companies-icon/circle-k.svg";
import wellsFargo from "../../../assets/events_images/companies-icon/wells-fargo.svg";
import albertsons from "../../../assets/events_images/companies-icon/albertsons.svg";
import me from "../../../assets/events_images/companies-icon/me.svg";
import salesforce from "../../../assets/events_images/companies-icon/salesforce.svg";
import comericabanklogo from "../../../assets/events_images/companies-icon/comerica-bank-logo.jpg";
import gaiglogo from "../../../assets/events_images/companies-icon/gaig-logo.jpg";
import nucleusteq from "../../../assets/events_images/companies-icon/nucleusteq.jpg";
import nuoData from "../../../assets/events_images/companies-icon/nuo-data.jpg";

const Partner: React.FC = () => {
  const data = [
    aws,
    aaa,
    ibm,
    amex,
    citizensBank,
    cava,
    mckesson,
    republicServices,
    jeniusBank,
    mmc,
    goDaddy,
    bannerHealth,
    blueCross,
    sprouts,
    discountTire,
    diamondBacks,
    petsmart,
    asu,
    circleK,
    wellsFargo,
    albertsons,
    me,
    salesforce,
    comericabanklogo,
    gaiglogo
  ];

  const data2 = [nucleusteq, nuoData];

  const data3 = [ibm];
  const data4 = [aws];
  return (
    <>
      <div className="partner-area ptb-120">
        <div className="container">
          <div className="partner-section-title">
            <h2>Attending</h2>
            <h1>Companies</h1>
          </div>
          <Row style={{ width: "100%" }} justify={"center"}>
            {data.map((e, i) => {
              return (
                <>
                  <Col key={i} className="partners-col">
                    <img
                      src={e}
                      className="partners-img"
                      alt="NucleusTeq"
                      loading="lazy"
                    />
                  </Col>
                </>
              );
            })}
          </Row>
        </div>
      </div>

      <div className="partner-area ptb-120 pt-0">
        <div className="container">
          <div className="partner-section-title">
            <h1>Sponsors</h1>
          </div>
          <Row style={{ width: "100%" }} justify={"center"}>
            <Col span={22}>
              <p className="diamond-sponsors">Diamond Sponsors</p>
              <Row style={{ width: "100%" }} justify={"center"}>
                {data2.map((e, i) => {
                  return (
                    <>
                      <Col key={i} className="partners-col">
                        <img
                          src={e}
                          className="partners-img"
                          alt="NucleusTeq"
                          loading="lazy"
                        />
                      </Col>
                    </>
                  );
                })}
              </Row>
              {/* <p className="platinum-sponsors">
                Platinum Sponsors
                <br />
              </p>

              <p className="gold-sponsors">
                Gold Sponsors
                <br />
              </p> */}
              <p className="gold-sponsors">
                Gold Sponsors
                <br />
              </p>
              <Row style={{ width: "100%" }} justify={"center"}>
                {data4.map((e, i) => {
                  return (
                    <>
                      <Col key={i} className="partners-col">
                        <img
                          src={e}
                          className="partners-img"
                          alt="NucleusTeq"
                          loading="lazy"
                        />
                      </Col>
                    </>
                  );
                })}
              </Row>

              <p className="silver-sponsors">
                Silver Sponsors
                <br />
              </p>
              <Row className="sponsors-row" style={{ width: "100%", marginBottom:"6vw" }} justify={"center"}>
                {data3.map((e, i) => {
                  return (
                    <>
                      <Col key={i} className="partners-col">
                        <img
                          src={e}
                          className="partners-img"
                          alt="NucleusTeq"
                          loading="lazy"
                        />
                      </Col>
                    </>
                  );
                })}
              </Row>
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default Partner;
