"use client";

import React from "react";
import { Link, useLocation } from "react-router-dom";

interface MenuItemProps {
  label: string;
  link: string;
  submenu?: { label: string; link: string }[];
  onClick?: () => void;
}

const MenuItem: React.FC<MenuItemProps> = ({
  label,
  link,
  submenu,
  onClick,
}) => {
  const pathname = useLocation();
  const isActive = pathname.pathname === link;

  if (submenu) {
    return (
      <li className="nav-item" key={label}>
        <Link
          to={link}
          className="nav-link"
          onClick={(e) => e.preventDefault()}
        >
          {label} <i className="icofont-thin-down d-none d-xl-inline"></i>
        </Link>

        <ul className="dropdown-menu">
          {submenu.map((subItem) => {
            const isActive = pathname.pathname == subItem.link;
            return (
              <li className="nav-item" key={subItem.label}>
                <Link
                  to={subItem.link}
                  className={`nav-link ${isActive ? "active" : ""}`}
                >
                  {subItem.label}
                </Link>
              </li>
            );
          })}
        </ul>
      </li>
    );
  }

  return (
    <li className="nav-item" key={label}>
      <Link
        to={link}
        className={`nav-link ${isActive ? "active" : ""}`}
        onClick={onClick}
      >
        {label}
      </Link>
    </li>
  );
};

export default MenuItem;
