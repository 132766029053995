import React from "react";
import { Col, Image, Row } from "antd";
import "./unclickableButton.scss";
type Props = {
  heading: string;
  subHeading: string;
  img: string;
  className: string;
  span1: number;
  span2: number;
};
const UnclickableButton = ({
  heading,
  img,
  subHeading,
  className,
  span1,
  span2,
}: Props) => {
  return (
    <>
      <Row>
        <Col span={span1} className="unclickable_col">
          <Image preview={false} src={img} className="unclickable-img" />
        </Col>
        <Col span={span2} className="unclickable_col">
          <Row>
            <Col span={24}>
              <p className="btnsheadingNew">{heading}</p>
              <p className="btnSubheadingNew childDivText4">{subHeading}</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </>
  );
};
export default UnclickableButton;
