import { List } from "antd";
import React, { useState } from "react";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";

interface FirstDayProps {
  title: string;
  subTitle: string;
  time: string;
  description: string;
  participants: any;
}

const FirstDay: React.FC<FirstDayProps> = ({
  title,
  subTitle,
  time,
  description,
  participants,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  const maxWords = 35;
  const descriptionWords = description.split(" ");
  const shouldTruncate = descriptionWords.length > maxWords;
  const truncatedDescription = shouldTruncate
    ? descriptionWords.slice(0, maxWords).join(" ") + "..."
    : description;

  return (
    <Accordion>
      <AccordionItem>
        <AccordionItemHeading>
          <AccordionItemButton>
            <div className="schedule-info row w-100">
              <h3>{title}</h3>
              <div className="col-lg-6 col-md-6">
                <h4>{subTitle}</h4>
              </div>

              <div className="col-lg-6 col-md-6 schedule-time">{time}</div>
              {description && (
                <>
                  <div className="schedule-desc">
                    <p>
                      {isExpanded ? description : truncatedDescription}
                      {shouldTruncate && !isExpanded && (
                        <span
                          onClick={toggleExpand}
                          style={{ color: "#e74860", cursor: "pointer" }}
                        >
                          {" "}
                          Show more
                        </span>
                      )}
                    </p>
                    {isExpanded && (
                      <span
                        onClick={toggleExpand}
                        style={{ color: "#e74860", cursor: "pointer" }}
                      >
                        {" "}
                        Show less
                        <br />
                        <br />
                      </span>
                    )}
                  </div>
                </>
              )}

              {participants && participants.length > 0 && (
                <>
                  <h4 style={{marginTop : "1.5%"}}>SPEAKERS</h4>
                  {/* <List
                    dataSource={participants}
                    renderItem={(item: any) => (
                      <>
                        <List.Item className="schedule-desc">
                          <li>{item}</li>
                        </List.Item>
                      </>
                    )}
                  /> */}
                  <div className="schedule-desc align-participants">
                    {participants.map((e:any) => {
                      return <li>⁍ {e}</li>
                      return <li>&bull;	⁍ ※ {e}</li>
                    })}
                  </div>
                </>
              )}
            </div>
          </AccordionItemButton>
        </AccordionItemHeading>
      </AccordionItem>
    </Accordion>
  );
};

export default FirstDay;
