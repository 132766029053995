import "./imageForLife.scss";
import { Layout, Row, Typography } from "antd";
import LifeCard from "../LifeCard/LifeCard";
import { NewTitle } from "../NewTitle";

type Props = {
  text: string;
  text1: string;
};

const ImageForLife = ({ text, text1 }: Props) => {
  const { Text } = Typography;
  return (
    <Layout className="imageContainering">
      <div className="image-bg-image">
        <Row className="imagesRow">
          {/* <NewTitle text={text} textTitleClassName="textTitleClassName" />
          <Text className="textNucleusteq">{text1}</Text> */}
        </Row>
      </div>
      <Row className="imageRow1st">
        <LifeCard />
      </Row>
    </Layout>
  );
};

export default ImageForLife;
