"use client";

import { Col, Row } from "antd";
import { Link } from "react-router-dom";
import React from "react";

const Subscribe: React.FC = () => {
  return (
    <>
      <div className="subscribe-area">
        <div>
          <div className="subscribe-inner">
            <p>
              <span>To become a sponsor, email</span>
            </p>
            <h2>
              <Link to="mailto:marketing@nucleusteq.com">
                marketing@nucleusteq.com
              </Link>
            </h2>

            {/* <form className="newsletter-form">
              <input
                type="email"
                className="form-control"
                placeholder="Enter your email address"
                name="EMAIL"
                required
              />
              <button className="btn btn-primary" type="submit">
                Send
              </button>
            </form> */}
            <p>Learn more about us</p>
            <Row gutter={[36, 0]} justify={"center"} className="learn-about-us">
              <Col>
                <Link target="_blank" to="https://nucleusteq.com">
                  https://nucleusteq.com
                </Link>
              </Col>
              <Col>
                <Link target="_blank" to="https://nuodata.io">
                  https://nuodata.io
                </Link>
              </Col>
            </Row>
          </div>
        </div>
      </div>
    </>
  );
};

export default Subscribe;
