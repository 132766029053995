import React, { useState, useEffect } from "react";
import "./header.scss";
import { CodeSandboxCircleFilled, MenuOutlined } from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import { Col, Row, Menu, Image, Space, Drawer, Button } from "antd";
import Logo from "../../../assets/images/header/nucleusteq_logo.svg";
import whitearrow from "../../../assets/images/header/white_arrow.svg";
import FunctionTitles from "../../Utiles/FunctionTitles";
import { MenuFoldOutlined, MenuUnfoldOutlined } from "@ant-design/icons";

const { SubMenu } = Menu;

const Header = () => {
  const currentRoute = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  const [activeStyle, setActiveStyle] = useState<string | null>(null);
  const [activePage, setActivePage] = useState<string | null>(null);
  const [activePageData, setActivePageData] = useState<any[]>([]);
  const [collapsed, setCollapsed] = useState(true);

  const toggleCollapsed = () => {
    setCollapsed(!collapsed);
  };

  const menuData = [
    {
      title: "Products",
      items: [
        { name: "FYNDR", url: "https://fyndr.us/", isProduct: true },
        { name: "NUODATA", url: "https://nuodata.io/", isProduct: true },
        {
          name: "NUODATA-DATA MODERNIZATION CLOUD",
          url: "https://nuodata.io/how-it-works/",
          isProduct: true,
        },
        {
          name: "NUODATA-DATA MANAGEMENT CLOUD",
          url: "https://nuodata.io/data-management/",
          isProduct: true,
        },
        { name: "DATA GOVERNANCE", url: "data-governance/" },
      ],
    },
    {
      title: "Industries",
      items: [
        { name: "BANKING & FINANCIAL", url: "bank/" },
        { name: "RETAIL, CONSUMER GOODS & SERVICES", url: "retail/" },
        { name: "INSURANCE", url: "insurance/" },
        { name: "LIFE SCIENCES & HEALTHCARE", url: "life/" },
        { name: "COMMUNICATION, MEDIA AND TECHNOLOGY", url: "communication/" },
        { name: "ENERGY & UTILITIES", url: "energy/" },
        { name: "TRAVEL AND HOSPITALITY", url: "travel/" },
      ],
    },
    {
      title: "Services",
      items: [
        { name: "ENTERPRISE DATA MODERNIZATION", url: "edm/" },
        { name: "ENTERPRISE DATA ADVISORY & ENGINEERING", url: "bigdata/" },
        { name: "AI , ML & ANALYTICS", url: "analytics/" },
        { name: "CLOUD SERVICES", url: "cloud/" },
        { name: "ENTERPRISE AUTOMATION", url: "auto/" },
        { name: "CRM AND ERP", url: "ui/" },
      ],
    },
    {
      title: "Overview",
      items: [
        { name: "ABOUT US", url: "aboutUs/" },
        { name: "LEADERSHIP", url: "leadership/" },
        { name: "PARTNERS", url: "partners/" },
        { name: "CUSTOMERS", url: "customers/" },
        { name: "SUCCESS STORIES", url: "successStories1/" },
      ],
    },
    // {
    //   title: "Insight",
    //   items: [
    //     { name: "THOUGHT LEADERSHIP", url: "thought-leadership/" },
    //     { name: "BLOG", url: "blog/" },
    //     { name: "NEWS", url: "news/" },
    //   ],
    // },
    {
      title: "Careers",
      items: [
        {
          name: "CAREER PORTAL",
          url: "https://nucleusteq.breezy.hr/",
          isProduct: true,
        },
        { name: "CAREER AT NUCLEUSTEQ", url: "careerPage/" },
      ],
    },
  ];

  const formattedMenuData = menuData.map((menu, menuIndex) => ({
    key: `menu${menuIndex + 1}`,
    label: menu.title,
    children: menu.items.map((item, itemIndex) => ({
      key: `menu${menuIndex + 1}-item${itemIndex + 1}`,
      label: (
        <Link
          to={item.url}
          style={{ textDecoration: "none" }}
          onClick={() => setCollapsed(true)}
          target={item.isProduct ? "_blank" : "_self"}
        >
          {item.name}
        </Link>
      ),
    })),
  }));

  const contactMenuItem = {
    key: `menu-contact`,
    label: (
      <Link
        to="/contact"
        style={{ textDecoration: "none" }}
        onClick={() => setCollapsed(true)}
      >
        Contact
      </Link>
    ),
  };

  const eventMenuItem = {
    key: `menu-event`,
    label: (
      <Link
        to="/events"
        style={{ textDecoration: "none" }}
        onClick={() => setCollapsed(true)}
      >
        Events
      </Link>
    ),
  };

  const combinedMenuData = [
    ...formattedMenuData,
    eventMenuItem,
    contactMenuItem,
  ];

  FunctionTitles("NucleusTeq | Home");

  const handleSubMenuClick = (title: string, items: any[]) => {
    setActivePage(title);
    setActivePageData(items);
    setIsOpen(true);
  };

  const renderSubMenu = (title: string, items: any[]) => (
    <SubMenu
      onTitleClick={() => {
        handleSubMenuClick(title, items);
      }}
      title={
        title === "Products" ? (
          <div
            style={{ display: "flex", alignItems: "center" }}
            onMouseEnter={() => {
              handleSubMenuClick(title, items);
            }}
          >
            <CodeSandboxCircleFilled
              className="anim"
              style={{
                backgroundColor: "#F0592A",
                borderRadius: "100%",
                color: "#F0592A",
              }}
            />{" "}
            Products
          </div>
        ) : (
          <div
            style={{ display: "flex", alignItems: "center" }}
            onMouseEnter={() => {
              handleSubMenuClick(title, items);
            }}
          >
            {title}
          </div>
        )
      }
      className={`subMenuItem`}
      style={{
        background:
          activeStyle === title
            ? "linear-gradient(137deg,#ffb6a0 0%, #f0592a 18.54%,#253c77 100%)"
            : "",
        height: "9vh",
        display: "flex",
        alignItems: "center",
      }}
    />
  );

  const renderMenuItems = (items: any[], isQuickLinks = false) =>
    items.map((menuItem, index) => (
      <Row key={index} onClick={() => menuChange(menuItem)}>
        <Space size={"small"} direction="horizontal">
          <img
            src={whitearrow}
            alt="whitearrow"
            style={{ alignSelf: "center" }}
          />
          <Col
            className={`header_menu-item ${isQuickLinks ? "quickLinks" : ""}`}
          >
            <Link
              to={menuItem.url}
              target={menuItem.isProduct ? "_blank" : "_self"}
              className="menu-text-decoration"
            >
              {menuItem.name}
            </Link>
          </Col>
        </Space>
      </Row>
    ));

  const menuChange = (value: any) => {
    if (value.isReady) {
    } else {
      const url = value.url.startsWith("http") ? value.url : `/${value.url}`;
      if (value.isProduct) {
        window.open(url, "_blank");
      } else {
        window.location.href = url;
      }
    }
  };

  useEffect(() => {
    if (currentRoute.pathname === "/contact") {
      setActiveStyle("Contact Us");
    } else if (currentRoute.pathname === "/events") {
      setActiveStyle("Events");
    } else {
      menuData.forEach((menu) => {
        menu.items.forEach((item) => {
          if (currentRoute.pathname.includes(item.url)) {
            setActiveStyle(menu.title);
          }
        });
      });
    }
    console.log("Current Path:", currentRoute.pathname, activeStyle);
  }, [currentRoute, activeStyle]);

  return (
    <>
      <Drawer
        title="Basic Drawer"
        placement={"top"}
        closable={true}
        onClose={() => setIsOpen(false)}
        open={isOpen}
        key={"placement"}
        style={{
          marginTop: "10vh",
          backgroundColor: "rgba(103, 119, 160, 0.3)",
          backdropFilter: "blur(5px)",
        }}
        headerStyle={{ display: "none" }}
        height={"90vh"}
      >
        <div className="subMenuP" onClick={() => setIsOpen(false)}>
          <div className="subMenuPC" onMouseLeave={() => setIsOpen(false)}>
            <div>
              <Row>
                <Col>
                  <Row className="header_menu-links">
                    {activePage === "Products"
                      ? "Products Links"
                      : activePage === "Industries"
                      ? "Industries"
                      : activePage === "Services"
                      ? "Services"
                      : activePage === "Overview"
                      ? "Overview Links"
                      : activePage === "Insight"
                      ? "Insight"
                      : activePage === "Careers"
                      ? "Careers"
                      : ""}
                  </Row>
                  {renderMenuItems(activePageData)}
                </Col>
                <Col>
                  {activePage &&
                  activePage !== "Overview" &&
                  [
                    "Products",
                    "Industries",
                    "Services",
                    "Overview",
                    "Insight",
                    "Careers",
                    "Events",
                    "Contact Us",
                  ].includes(activePage) ? (
                    <Col
                      className="QuickLinkCol"
                      style={{ paddingLeft: "5rem" }}
                    >
                      <Row className="header_menu-links quickLinks">
                        Quick links
                      </Row>
                      {renderMenuItems(
                        menuData.find((menu) => menu.title === "Overview")
                          ?.items || [],
                        true
                      )}
                    </Col>
                  ) : null}
                </Col>
              </Row>
            </div>
          </div>
        </div>
      </Drawer>

      <div
        className="menuDiv"
        style={{ position: "sticky", top: 0, width: "100%", zIndex: "1002" }}
      >
        <Row className="infoRow">
          <Col xs={14} sm={7} md={7} lg={7} xl={6} xxl={7}>
            <div className="infoColImage">
              <Link to="/">
                <Image
                  preview={false}
                  src={Logo}
                  loading="lazy"
                  // onClick={() => {
                  //   FunctionTitles("NucleusTeq | Home 55");
                  // }}
                  className="headerLogo"
                />
              </Link>
            </div>
          </Col>
          <Col xs={0} sm={0} md={16} lg={17} xl={18} xxl={17}>
            <Menu
              mode="horizontal"
              overflowedIndicator={<MenuOutlined className="headerMenu" />}
              style={{
                marginLeft: "8vw",
                justifyContent: "end",
                display: "flex",
                zIndex: "2000",
              }}
              className="menuColStyle"
            >
              {menuData.map((menuItem, index) =>
                renderSubMenu(menuItem.title, menuItem.items)
              )}
              <SubMenu
                onTitleClick={() => setActivePage("Events")}
                onTitleMouseEnter={() => setIsOpen(false)}
                key="dropdown7"
                title={
                  <Link
                    to="/events"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    Events
                  </Link>
                }
                className={`subMenuItem`}
                style={{
                  background:
                    activeStyle === "Events"
                      ? "linear-gradient(137deg,#ffb6a0 0%, #f0592a 18.54%,#253c77 100%)"
                      : "",
                  height: "9vh",
                  display: "flex",
                  alignItems: "center",
                }}
              />
              <SubMenu
                onTitleClick={() => setActivePage("Contact Us")}
                onTitleMouseEnter={() => setIsOpen(false)}
                key="dropdown8"
                title={
                  <Link
                    to="/contact"
                    style={{ textDecoration: "none", color: "#fff" }}
                  >
                    Contact Us
                  </Link>
                }
                className={`subMenuItem`}
                style={{
                  background:
                    activeStyle === "Contact Us"
                      ? "linear-gradient(137deg,#ffb6a0 0%, #f0592a 18.54%,#253c77 100%)"
                      : "",
                  height: "9vh",
                  display: "flex",
                  alignItems: "center",
                }}
              />
            </Menu>
          </Col>
          <Col
            className="infoColManu"
            xs={8}
            sm={10}
            md={0}
            lg={0}
            xl={0}
            xxl={0}
            style={{ position: "absolute", right: "0", top: "2vh" }}
          >
            <Button
              onClick={toggleCollapsed}
              style={{ background: "transparent", border: "transparent" }}
            >
              {collapsed ? (
                <MenuUnfoldOutlined
                  style={{ fontSize: "20px", color: "#fff" }}
                />
              ) : (
                <MenuFoldOutlined style={{ fontSize: "20px", color: "#fff" }} />
              )}
            </Button>
            {collapsed === false && (
              <div className="menu-mask" onClick={toggleCollapsed} />
            )}
            <Menu
              mode="inline"
              theme="dark"
              inlineCollapsed={collapsed}
              items={combinedMenuData}
              className="mobMenuStyle"
              style={{
                width: "100vw",
                position: "absolute",
                right: "0",
                top: "8vh",
              }}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

export default Header;
