import React from "react";
import "./newText.scss"
type Props = {
  newtext: string;
};

const NewText = ({ newtext }: Props) => {
  return (
    <div style={{marginTop:"3vw"}} className="newText">
      <span>
       {newtext}
      </span>
    </div>
  );
}

export default NewText;