// import title0 from "../assets/images/home/dataUniversePlatform.svg";
import title0 from "../assets/images/home/DU.svg";
import title2 from "../assets/images/home/single.svg";
import title1 from "../assets/images/home/modernize.svg";
import title5 from "../assets/images/home/attended.svg";
import title3 from "../assets/images/home/cloud.svg";
import title4 from "../assets/images/home/ai-ml.svg";
import title6 from "../assets/images/home/crm.svg";
import home1stBg from "../assets/images/home/event-slider.png";
import home4thBg from "../assets/images/home/slider1.png";

const homePageCarouselData = [
  {
    id: 0,
    title: title0,
    subTitle: "October 17-18, 2024 JW Marriott Desert Ridge, Phoenix, AZ",
    bgImage: home1stBg,
    redirectionUrl: "",
    link: "/events",
  },
  {
    id: 1,
    title: title1,
    subTitle:
      "Unleash Efficiency, Accelerate Innovation, Transform Experiences",
    bgImage: "all-graph/carousel/modernize/modernize.html",
    redirectionUrl: "",
    link: "/edm",
  },
  {
    id: 2,
    title: title2,
    subTitle: "The Enterprise Data Lake House Advantage",
    bgImage: "all-graph/carousel/data-lake/demo/data.html",
    redirectionUrl: "",
    link: "/bigdata",
  },
  {
    id: 3,
    title: title3,
    subTitle: "Embrace Change, Focus on Core, Optimize Costs",
    bgImage: home4thBg,
    redirectionUrl: "",
    link: "/cloud",
  },
  {
    id: 4,
    title: title4,
    subTitle: "Innovate with Insights",
    bgImage: "all-graph/carousel/AIML/data.html",
    redirectionUrl: "",
    link: "/analytics",
  },
  {
    id: 5,
    title: title5,
    subTitle: "Elevate Efficiency with Enterprise Automation and RPA",
    bgImage: "all-graph/carousel/bots/bots.html",
    redirectionUrl: "",
    link: "/auto",
  },
  {
    id: 6,
    title: title6,
    subTitle: "Integrate to Innovate",
    bgImage: "all-graph/carousel/CRM/demo/data.html",
    redirectionUrl: "",
    link: "/ui",
  },
];

export { homePageCarouselData };
