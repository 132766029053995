import { Row, Col, Space, Modal } from "antd";
import CommonButton from "../../../Components/CommonButton/CommonButton";
import active from "../../../assets/images/ProductIntroduction/active.png";
import chart from "../../../assets/images/ProductIntroduction/chart.png";
import map from "../../../assets/images/ProductIntroduction/map.png";
import review from "../../../assets/images/ProductIntroduction/review.png";
import UnclickableButton from "../../../Components/UnclickableButton/UnclickableButton";
// import image from "../../assets/images/ProductIntroduction/IntroducingOurProduct.png";
import image from "../../../assets/images/ProductIntroduction/IntroducingOurProduct.png";

import "./productSection.scss";
import { useState } from "react";
import { BsArrowRight } from "react-icons/bs";
const Productsection = () => {
  const [allVisible, setAllVisible] = useState(false);
  return (
    <>
      <Row className="product_mainRowProduct">
        <Row style={{ width: "100%", textAlign: "center" }} justify={"center"}>
          <h1 className="commonTitleHead">ABOUT US</h1>
        </Row>
        <Row justify={"center"} style={{ width: "100%" }}>
          <div className="product_orange-gradient" />
        </Row>
        <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12}>
          <div className="productMainDiv">
            <div
              className="childDiv"
              // style={{
              //   background: `url(${image}) no-repeat center`,
              //   backgroundRepeat: "no-repeat",
              //   backgroundSize: "contain",
              //   backgroundPosition: "center",
              //   width: "100%",
              //   height: "100%",
              //   position: "relative",
              // }}
              onClick={() => {
                setAllVisible(true);
                // hideButton(true)
              }}
            >
              <Row>
                <Col span={24} className="childDiv1">
                  <div className="childDivData childDivData1">
                    <UnclickableButton
                      img={chart}
                      heading="$200M+"
                      subHeading="Enterprise Cost Savings"
                      className="btn1"
                      span1={10}
                      span2={14}
                    />
                  </div>
                </Col>
                <Col span={24} className="childDiv2">
                  <div className="childDivData childDivData2">
                    {" "}
                    <UnclickableButton
                      img={active}
                      heading="+3"
                      subHeading="Dedicated Innovation Labs"
                      className="btn2"
                      span1={10}
                      span2={14}
                    />
                  </div>
                </Col>
                <Col span={24} className="childDiv2">
                  <div className="childDivData childDivData4">
                    {" "}
                    <UnclickableButton
                      img={map}
                      heading="8+"
                      subHeading="Global Delivery Centers"
                      className="btn2"
                      span1={10}
                      span2={14}
                    />
                  </div>
                </Col>
                <Col span={24} className="childDiv3">
                  <div className="childDivData childDivData3">
                    <UnclickableButton
                      img={review}
                      heading="Top 10"
                      subHeading="Big Data Consulting Companies in the US by CIO Review"
                      className="btn3"
                      span1={8}
                      span2={16}
                    />
                  </div>
                </Col>
              </Row>
            </div>
          </div>
        </Col>

        <div className="product_mobColProduct">
          <Space direction="vertical">
            <div className="childDivDataMob">
              <UnclickableButton
                img={chart}
                heading="$200M+"
                subHeading="Enterprise Cost Savings"
                className="btn1"
                span1={10}
                span2={14}
              />
            </div>
            <div className="childDivDataMob">
              <UnclickableButton
                img={active}
                heading="+3"
                subHeading="Dedicated Innovation Labs"
                className="btn2"
                span1={10}
                span2={14}
              />
            </div>
            <div className="childDivDataMob">
              <UnclickableButton
                img={map}
                heading="8+"
                subHeading="Global Delivery Centers"
                className="btn2"
                span1={10}
                span2={14}
              />
            </div>
            <div className="childDivDataMob">
              <UnclickableButton
                img={review}
                heading="10+"
                subHeading="Big Data Consulting Companies in the US by CIO Review"
                className="btn3"
                span1={9}
                span2={15}
              />
            </div>
          </Space>
        </div>

        <Col
          xs={24}
          sm={24}
          md={24}
          lg={24}
          xl={12}
          xxl={12}
          className="product_textColProduct"
        >
          <div className="childdivtxt">
            <Row>
              <Col>
                <Row className="product_heading">
                  INTRODUCING NUCLEUSTEQ, THE DRIVING FORCE BEHIND DIGITAL AND
                  DATA TRANSFORMATION FOR <span>FORTUNE 1000 CLIENTS</span>{" "}
                  WORLDWIDE.
                </Row>
                <Row className="product_subHeading">
                  NucleusTeq is a software services, solutions & products
                  company empowering & transforming customers’ businesses
                  through digital technologies such as big data, Analytics (AI,
                  ML), Cloud, Enterprise Automation, Blockchain, Mobility, CRM &
                  ERP
                </Row>
                <Row>
                  <CommonButton
                    buttonText="Discover the Benefits"
                    link="/aboutUs"
                    style={{ width: "35vh" }}
                    icon={<BsArrowRight />}
                  />
                </Row>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
      <Modal
        open={allVisible}
        footer={null}
        // closeIcon={closeIcon}
        onCancel={() => {
          setAllVisible(false);
        }}
        title="Contact us"
        className="referModalMObileView"
      >
        <div>
          <p>
            <iframe
              src="https://www.youtube.com/embed/QwNjGPEEi5s"
              width="100%"
              height="315"
              frameBorder={0}
              allowFullScreen
            >
              <span
                data-mce-type="bookmark"
                style={{
                  display: "inlineBlock",
                  width: " 0px",
                  overflow: "hidden",
                  lineHeight: 0,
                }}
                className="mce_SELRES_start"
              >
                &#xFEFF;
              </span>
              <span
                data-mce-type="bookmark"
                style={{
                  display: "inline-block",
                  width: "0px",
                  overflow: "hidden",
                  lineHeight: 0,
                }}
                className="mce_SELRES_start"
              >
                &#xFEFF;
              </span>
            </iframe>
          </p>
        </div>
      </Modal>
    </>
  );
};
export default Productsection;
