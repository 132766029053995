"use client";

import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import FirstDay from "./FirstDay";

const EventSchedules: React.FC = () => {
  const eventsData1 = [
    {
      title: "NETWORKING",
      subTitle: "Registration, Lunch and Check-In",
      time: "12:00PM - 1:00PM",
      description: "",
    },
    {
      title: "WELCOME",
      subTitle: "Welcome & Opening Remarks",
      time: "1:00PM - 1:10PM",
      description: "",
    },
    {
      title: "OPENING KEYNOTE",
      subTitle:
        "Reinvent or Become Irrelevant: The Blueprint of a Modern Data & Analytics Strategy",
      time: "1:10PM - 1:40PM",
      description:
        "As AI accelerates, data & analytics leaders must become the champions of business innovation. Traditional strategies, governance, and solution delivery need a complete overhaul. Discover insights from Gartner's latest CDAO Agenda Survey and learn how to prepare your data universe of the future, today.",
    },
    {
      title: "KEYNOTE CLIENT PANEL",
      subTitle: "AI in Business - Use Cases & Innovations",
      time: "1:40PM - 2:20PM",
      description:
        "Join CIOs from leading companies as we explore the macro trends shaping the future of data and AI across industries like retail, financial services, and more. Discover how businesses are using data to drive personalization, enhance customer experiences, manage risks, and fuel business growth. Learn about the strategies that have led to success, the challenges encountered, and the key lessons that can help you refine and optimize your own data and AI initiatives.",
    },
    {
      title: "BREAK / NETWORKING",
      subTitle: "Networking Break / Interactive Demos",
      time: "2:20PM - 2:40PM",
      description: "",
    },
    {
      title: "KEYNOTE",
      subTitle: "Build Your Data Universe for an AI-ready Future",
      time: "2:40PM - 3:10PM",
      description:
        'AI has brought both groundbreaking advancements and critical challenges, such as energy concerns, bias, and business relevance. It has highlighted the urgent need to strengthen data and analytics platforms for an AI-driven future. Discover what it means to have "AI-ready" data and push the boundaries of data quality, structure, lineage, security, governance, and metadata. While AI is faster, it’s not necessarily smarter than we are.',
    },
    {
      title: "SUCCESS SPOTLIGHT",
      subTitle: "Banking Reinvented: Jenius Bank",
      time: "3:10PM - 3:40PM",
      description: "Jenius Bank is a digital-first institution offering personalized banking through a seamless app-based experience. It provides savings, checking, and lending services, prioritizing convenience, security, and smart financial tools. Leveraging AI, Jenius delivers tailored advice, helping customers manage budgets and achieve financial goals efficiently.",
    },
    {
      title: "PEER TALK: CLIENT PANEL",
      subTitle: "Data Governance & AI: Balancing Innovation & Compliance",
      time: "3:40PM - 4:20PM",
      description:
        "Data is the most important asset of an organization, central to analytics, AI, and machine learning. Join our panel of experts as we navigate the landscape of AI and explore the emerging trends in data management.  Learn about essential AI governance principles to ensure quality and privacy, and how to manage data governance in multi-cloud, hybrid environments.",
    },
    {
      title: "BREAK / NETWORKING",
      subTitle: "Networking Break / Interactive Demos",
      time: "4:20PM - 4:35PM",
      description: "",
    },
    {
      title: "PARTNER SPOTLIGHT: NUODATA",
      subTitle: "Revolutionizing Data Modernization, Management & AI",
      time: "4:35PM - 5:00PM",
      description:
        "NuoData provides cutting-edge solutions for data modernization, management, and analytics. Its platform simplifies the migration of legacy systems, enhances data governance, and enables real-time insights through AI-driven analytics. NuoData empowers businesses to unlock the full potential of their data for smarter decision-making and operational efficiency.",
    },
    {
      title: "PARTNER PANEL",
      subTitle: "Data Modernization: Transforming Legacy Systems",
      time: "5:00PM - 5:30PM",
      description: "AI transforms legacy systems by automating data migration, optimizing processes, and enhancing performance. Advanced AI tools streamline the transition from outdated infrastructure to modern platforms, enabling real-time analytics, cost efficiency, and scalability, while preserving data integrity and ensuring seamless integration across evolving technology landscapes.",
    },
    {
      title: "CLOSING REMARKS",
      subTitle: "Closing Remarks",
      time: "5:30PM",
      description: "",
    },
    {
      title: "RECEPTION & COCKTAILS",
      subTitle: "Reception &  networking at Tia Carmen",
      time: "5:30PM - 6:30PM",
      description: "",
    },
    {
      title: "DINNER",
      subTitle: "Dinner at Tia Carmen",
      time: "6:30PM - 8:00PM",
      description: "",
    },
  ];

  const eventsData2 = [
    {
      title: "NETWORKING (Optional)",
      subTitle: "Optional Golf Outing",
      time: "8:00AM - 12:00PM",
      description: "",
    },
  ];

  return (
    <>
      <div className="schedule-area bg-image">
        <div className="agenda-top-area" />
        <div className="container">
          <div className="section-title" style={{ position: "relative" }}>
            <h1>Agenda</h1>
          </div>

          <div className="row" >
            <div className="col-lg-12">
              <Tabs className="event-tabs">
                <TabList>
                  <Tab style={{ zIndex: "999" }}>
                    First Day
                    <span>October 17, 2024</span>
                  </Tab>

                  <Tab style={{ zIndex: "999" }}>
                    Second Day
                    <span>October 18, 2024</span>
                  </Tab>
                </TabList>

                <TabPanel>
                  {eventsData1.map((event, index) => (
                    <div
                      className={
                        index === eventsData1.length - 1
                          ? "events-last-div"
                          : "events-div"
                      }
                    >
                      <FirstDay
                        key={index}
                        title={event.title}
                        subTitle={event.subTitle}
                        time={event.time}
                        description={event.description}
                      />
                    </div>
                  ))}
                </TabPanel>

                <TabPanel>
                  {eventsData2.map((event, index) => (
                    <div
                      style={{
                        marginBottom:
                          index === eventsData2.length - 1 ? "0" : "4vh",
                      }}
                    >
                      <FirstDay
                        key={index}
                        title={event.title}
                        subTitle={event.subTitle}
                        time={event.time}
                        description={event.description}
                      />
                    </div>
                  ))}
                </TabPanel>
              </Tabs>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EventSchedules;
